<template>
  <div class="days-period-wrap">
    <div class="days-period-wrap__days">
      <div
        v-for="day in days"
        :class="['day', { 'day--active': day.active }]"
        :key="day.key"
        @click="toggleActiveDay(day, days)"
      >
        {{ day.name }}
      </div>
    </div>
    <div v-if="isOtherDays" class="days-period-wrap__other-days">
      <div
        v-for="day in otherDays"
        :class="['day', { 'day--active': day.active }]"
        :key="day.key"
        @click="selectOtherDays(day, otherDays)"
      >
        {{ day.name }}
      </div>
    </div>
    <div style="display: none" class="days-period-wrap__checkbox disabled">
      <skif-checkbox v-model="notCelebrateDays" id="notCelebrateDays" />
      <label for="notCelebrateDays">
        {{ $t('routesTrips.trip.date.cancel_days_period') }}
      </label>
    </div>
    <div class="days-period-wrap__btns">
      <skif-button
        class="secondary w-full cancel"
        type="button"
        @click="$emit('close-days-period')"
      >
        {{ $t('measure.cancel') }}
      </skif-button>
      <skif-button class="w-full" type="button" @click="filterDays">
        {{ $t('routesTrips.trip.date.filter_days') }}
      </skif-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DaysPeriod',
  data() {
    return {
      days: [
        {
          key: 'Monday',
          name: this.$t('routesTrips.trip.date.week_days.monday'),
          active: true
        },
        {
          key: 'Tuesday',
          name: this.$t('routesTrips.trip.date.week_days.tuesday'),
          active: true
        },
        {
          key: 'Wednesday',
          name: this.$t('routesTrips.trip.date.week_days.wednesday'),
          active: true
        },
        {
          key: 'Thursday',
          name: this.$t('routesTrips.trip.date.week_days.thursday'),
          active: true
        },
        {
          key: 'Friday',
          name: this.$t('routesTrips.trip.date.week_days.friday'),
          active: true
        },
        {
          key: 'Saturday',
          name: this.$t('routesTrips.trip.date.week_days.saturday'),
          active: true
        },
        {
          key: 'Sunday',
          name: this.$t('routesTrips.trip.date.week_days.sunday'),
          active: true
        }
      ],
      otherDays: [
        {
          key: 'all-days',
          name: this.$t('routesTrips.trip.date.week_days.all_days'),
          active: true
        },
        {
          key: 'even',
          name: this.$t('routesTrips.trip.date.week_days.even'),
          active: false
        },
        {
          key: 'odd',
          name: this.$t('routesTrips.trip.date.week_days.odd'),
          active: false
        }
      ],
      notCelebrateDays: false,
      selectDays: []
    }
  },
  methods: {
    selectOtherDays(day, array) {
      array.map((el) => (el.active = false))
      const index = array.findIndex((el) => el.key === day.key)
      array[index].active = !array[index].active
      const dayIndex = this.selectDays.findIndex((el) => el.key === day.key)
      if (dayIndex === -1) {
        this.selectDays.push(day)
      } else {
        this.selectDays.splice(dayIndex, 1)
      }
    },
    toggleActiveDay(day, array) {
      const index = array.findIndex((el) => el.key === day.key)
      array[index].active = !array[index].active
      const dayIndex = this.selectDays.findIndex((el) => el.key === day.key)
      if (dayIndex === -1) {
        this.selectDays.push(day)
      } else {
        this.selectDays.splice(dayIndex, 1)
      }
    },
    filterDays() {
      this.$emit('accept-filter-period', this.selectDays)
    },
    handleClickDaysPeriod(event) {
      const wrap = document.querySelector('.days-period-wrap')
      if (!wrap.contains(event.target)) {
        this.$emit('close-days-period')
      }
    }
  },
  mounted() {
    setTimeout(() => {
      document.addEventListener('click', this.handleClickDaysPeriod)
    }, 100)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickDaysPeriod)
  }
}
</script>

<style lang="scss" scoped>
.days-period-wrap {
  position: absolute;
  z-index: 60;
  width: 342px;
  padding: 16px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px #00000029;
  color: #446c9e;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  &__days {
    display: flex;
    border-radius: 4px;
    border: 1px solid #c9d6de;
    .day {
      width: 100%;
      border-right: 1px solid #c9d6de;
      text-align: center;
      height: 27px;
      align-items: center;
      display: flex;
      justify-content: center;
      &:last-child {
        border-right: none;
      }
      &--active {
        background-color: #5c9deb;
        color: #fff;
      }
    }
  }
  &__other-days {
    display: flex;
    border-radius: 4px;
    border: 1px solid #c9d6de;
    margin-top: 12px;
    .day {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 27px;
      border-right: 1px solid #c9d6de;
      text-transform: none;
      &:last-child {
        border-right: none;
      }
      &--active {
        background-color: #5c9deb;
        color: #fff;
      }
    }
  }
  &__checkbox {
    margin-top: 8px;
    text-transform: none;
  }
  &__btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    margin-top: 8px;
    .cancel {
      background-color: #9dbdd1;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
